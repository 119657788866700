<template>
  <div v-if="toggle" class="modal-overlay" @click="closeModal">
    <div class="modal-content modal-size" @click.stop>
      <div class="row">
        <div class="my-3 col-12 text-center">
          <h5>Are you sure you want to delete this signature?</h5>
        </div>
      </div>
      <div class="row justify-content-center mt-4">
        <a class="btn btn-secondary" @click="closeModal">Cancel</a>
        <a class="btn" style="background-color: #f07a7a; color: white;" @click="deleteSignatureConfirmed">Confirm</a>
      </div>
    </div>
  </div>
</template>

<script>
import emitter from '../../eventBus'
import { mapActions } from 'vuex';
import { toast } from '@/utils/utils';

export default {
  name: 'DeleteSignature',
  data() {
    return {
      toggle: false,
      signatureID: ""
    }
  },
  mounted() {
    const self = this;
    emitter.on('openDeleteSignatureModal', function (data) {
      if (data.signatureID){
        self.signatureID = data.signatureID;
        self.showModal();
      } else console.log("Signature ID missing...")
    });
  },
  methods: {
    ...mapActions(['deleteSignature']),
    showModal() {
      this.toggle = true;
      this.name = "";
    },
    closeModal() {
      this.toggle = false;
    },
    async deleteSignatureConfirmed() {
      emitter.emit('openLoadingModal', {text: 'Deleting...'});
      const result = await this.deleteSignature({id: this.signatureID});
      emitter.emit('closeLoadingModal');
      if (result) {
        this.toggle = false;
        if (!this.$route.path.startsWith('/dashboard')) return this.$router.push('/dashboard');
        toast('Signature was deleted successfully');
      }
    }
  }
}
</script>

<style scoped>


</style>
