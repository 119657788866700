<template>
  <div v-if="toggle" class="modal-overlay" @click="closeModal" style="padding: 10px;">
    <div class="modal-content modal-size" style="width: 500px !important;" @click.stop>
      <img src="https://cdn.mailsign.link/images/mailsign.png" alt="" width="70" class="mx-auto mb-4">
      <h3 class="text-center" style="color: #1d1d1d;">Welcome to MailSign</h3>
      <p class="text-center mt-2" style="color: #333333;">Create professional email signatures <br> for you and your entire team</p>
      <span class="text-center mt-4" style="font-size: 14px;">Continue with the current Outlook account</span>
      <button @click="useOfficeAccount" class="btn border p-2 mt-1" style="border-radius: 50px !important; color: white; background: #106BBD;">{{officeAccountEmail}}</button>
      <button @click="useDifferentAccount" class="btn border p-2 mt-4" style="border-radius: 50px !important; color: #3e3e3e;">or use different account</button>
    </div>
  </div>
</template>
<script>
import emitter from '../../eventBus'
import { mapActions } from 'vuex';
import { PublicClientApplication } from "@azure/msal-browser";


const pca = new PublicClientApplication({
  auth: {
    clientId: '93e65027-b906-4b68-9aa9-efb3a1b2f22b',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: 'https://localhost:8080/office/login/callback' // Must be registered as "spa" type
  },
  cache: {
    cacheLocation: 'localStorage', // needed to avoid "login required" error
    storeAuthStateInCookie: true   // recommended to avoid certain IE/Edge issues
  }
});


export default {
  name: 'OutlookAutoLogin.vue',
  data() {
    return {
      toggle: false
    }
  },
  computed: {
    officeAccountEmail() {
      return Office.context?.mailbox?.userProfile?.emailAddress || 'Current Account'
    }
  },
  mounted() {
    const self = this;
    emitter.on('openOutlookAutoLoginModal', function (data) {
      self.showModal();
    });
    emitter.on('closeOutlookAutoLoginModal', function () {
      self.closeModal();
    });
  },
  methods: {
    ...mapActions(["microsoftOBO", "microsoftLogin", "loginSetup"]),
    showModal() {
      this.toggle = true;
    },
    closeModal() {
      this.toggle = false;
    },
    async useOfficeAccount() {

        let response;
        try {
          let token = await Office.getAccessToken({allowConsentPrompt: true, allowSignInPrompt: true});
          response = await this.microsoftOBO({ token });
          response = await this.microsoftLogin({ accessToken: response.accessToken});
        } catch (error) {
          await pca.initialize();
          response = await pca.loginPopup({
            scopes: ['user.read']
          });
          response = await this.microsoftLogin({ accessToken: response.accessToken});
        }
          this.loginSetup(response);
          this.closeModal();

    },
    useDifferentAccount() {
      this.closeModal();
      emitter.emit('openLoginModal', { state: 'outlook_addon' });
    }
  }
}
</script>

<style scoped>
  .modal-content {
    width: 700px;
    min-height: 300px;
  }
</style>
