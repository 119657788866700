<template>
  <div v-if="toggle" class="modal-overlay" @click="closeModal">
    <div class="modal-content modal-size" style="width: 500px !important;" @click.stop>
        <span class="closeBtn" @click="closeModal">x</span>
        <h5 class="loading" v-if="!iframeLoaded">Loading..</h5>
        <iframe @load="iframeLoadedEvent" v-show="iframeLoaded" :src="`${allowedOrigin}/login?response_type=code&display=popup&realm=mailsign${authState ? ('&state=' + authState) : ''}`" width="100%" height="600px" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
import emitter from '../../eventBus'
export default {
  name: 'LoginModal',
  data() {
    return {
      toggle: false,
      authState: null,
      iframeLoaded: false
    }
  },
  mounted() {
    const self = this;
    emitter.on('openLoginModal', function (data) {
      self.iframeLoaded = false
      self.authState = null;
      if (data?.state) self.authState = data.state;
      self.showModal();
    });
    emitter.on('closeLoginModal', function () {
      self.closeModal();
    });
  },
  computed: {
    allowedOrigin () {
      return import.meta.env.VITE_IFRAME_AUTH_URL;
    }
  },
  methods: {
    showModal() {
      this.toggle = true;
    },
    closeModal() {
      this.toggle = false;
    },
    iframeLoadedEvent() {
      this.iframeLoaded = true;;
    }
  }
}
</script>

<style scoped>
  .modal-content {
    width: 700px;
    min-height: 300px;
  }

  .closeBtn {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 20px;
    cursor: pointer;
  }

  .loading {
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: gray;
  }


  .loading:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {
    0%, 20% {
      color: rgba(0,0,0,0);
      text-shadow:
          .25em 0 0 rgba(0,0,0,0),
          .5em 0 0 rgba(0,0,0,0);}
    40% {
      color: white;
      text-shadow:
          .25em 0 0 rgba(0,0,0,0),
          .5em 0 0 rgba(0,0,0,0);}
    60% {
      text-shadow:
          .25em 0 0 white,
          .5em 0 0 rgba(0,0,0,0);}
    80%, 100% {
      text-shadow:
          .25em 0 0 white,
          .5em 0 0 white;
    }
  }

</style>
