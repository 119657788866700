import Api from '@/services/Api';

export default {
	getSignature (data) {
		return Api().get('/mailsign/signature/' + data.id)
	},
	getSignatureTemplate (data) {
		return Api().get(`/order/templates/${data.id}?type=signature`)
	},
	getSignatures () {
		return Api().get('/mailsign/signatures')
	},
	saveSignature (data) {
		return Api().post('/mailsign/signature', data)
	},
	generateSignature (data) {
		return Api().post('/mailsign/signature/generate', {data});
	},
	renameSignature (data) {
		return Api().put('/mailsign/signature/rename', data);
	},
	duplicateSignature (data) {
		return Api().post('/mailsign/signature/duplicate', data);
	},
	deleteSignature (data) {
		return Api().delete('/mailsign/signature/' + data.id);
	}
}
