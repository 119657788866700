import { useToast } from 'vue-toastification';
import QRCodeStyling from 'qr-code-styling';
import  vCardJS  from '@tapni/vcards-js'

const toast = useToast();

function formatSize(size) {
	if (size > 1024 * 1024 * 1024 * 1024) {
		return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB';
	} else if (size > 1024 * 1024 * 1024) {
		return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB';
	} else if (size > 1024 * 1024) {
		return (size / 1024 / 1024).toFixed(2) + ' MB';
	} else if (size > 1024) {
		return (size / 1024).toFixed(2) + ' KB';
	}
	return size.toString() + ' B';
}

function cleanSignatureHTML(html) {
	return html.replaceAll('<!--v-if-->', '').replaceAll(/\b data-[\w-=""]*/g, "").replaceAll('<!---->', '').trim();
}

function updateJson(originalJson, updates) {
	Object.keys(updates).forEach(key => {
		if (originalJson.hasOwnProperty(key)) {
			originalJson[key] = updates[key];
		}
	});
	return originalJson;
}


const blobToBase64 = blob => {
	const reader = new FileReader();
	reader.readAsDataURL(blob);
	return new Promise(resolve => {
		reader.onloadend = () => {
			resolve(reader.result);
		};
	});
};

const arrayBufferToBase64 = function(buffer) {
	var binary = '';
	var bytes = new Uint8Array(buffer);
	var len = bytes.byteLength;
	for (var i = 0; i < len; i++) {
		binary += String.fromCharCode(bytes[i]);
	}
	return window.btoa(binary);
}

const imgSquareToRound = async function (imageUrl) {
	return new Promise((resolve, reject) => {
		// Create an image element
		let img = new Image();
		img.crossOrigin = 'anonymous'; // Important for CORS if the image is from a different domain

		img.onload = function() {
			// Create a temporary canvas
			let canvas = document.createElement('canvas');
			let ctx = canvas.getContext('2d');

			// Assuming the image is square
			let size = Math.min(img.width, img.height);

			// Set canvas size
			canvas.width = size;
			canvas.height = size;

			// Create a circular clipping path
			ctx.beginPath();
			ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2, true);
			ctx.closePath();
			ctx.clip();

			// Draw the image onto the canvas
			ctx.drawImage(img, 0, 0, size, size);

			// Convert the canvas content to a Base64 string
			let base64Image = canvas.toDataURL("image/png");

			// Resolve the Promise with the Base64 string
			resolve(base64Image);
		};

		img.onerror = function() {
			reject(new Error("Failed to load image at " + imageUrl));
		};

		// Set the source of the image
		img.src = imageUrl;
	});
}

async function generateQRCode(data){

	const qrCode = new QRCodeStyling({
		width: 300,
		height: 300,
		margin: 2,
		data: data.value,
		dotsOptions: {
			color: data.color,
			type: data.style,
		},
		backgroundOptions: {
			color: '#ffffff'
		},
		cornersSquareOptions: {
			type: data.style
		}
		/*
			imageOptions: {
			crossOrigin: "anonymous",
			imageSize: 0.5,
		},
 */
	});

	let qrImage = await qrCode.getRawData("png");
	return await blobToBase64(qrImage)

}

function generateVcard(vcard) {

	// create a new vCard
	let vCard = vCardJS();

	if (vcard.vcardName) {
		// New code
		vCard.firstName = vcard.vcardName;
	} else {
		// Old code
		vCard.firstName = vcard.name || '';
	}

	vCard.homePhone = [];
	vCard.workPhone = [];
	vCard.homeFax = [];
	vCard.workFax = [];
	vCard.email = [];
	vCard.workEmail = [];
	vCard.url = [];
	vCard.workUrl = [];

	// new code v2
	if (vcard.vcardFirstName) vCard.firstName = vcard.vcardFirstName;

	if (vcard.vcardMiddleName) vCard.middleName = vcard.vcardMiddleName;
	if (vcard.vcardLastName) vCard.lastName = vcard.vcardLastName;
	if (vcard.vcardCompanyName) vCard.organization = vcard.vcardCompanyName;

	if (vcard.vcardHomePhone) vCard.homePhone.push(vcard.vcardHomePhone);
	if (vcard.vcardPhone) vCard.homePhone.push(vcard.vcardPhone);
	if (vcard.vcardPhone2) vCard.homePhone.push(vcard.vcardPhone2);
	if (vcard.vcardPhone3) vCard.homePhone.push(vcard.vcardPhone3);
	if (vcard.vcardWorkPhone) vCard.workPhone.push(vcard.vcardWorkPhone);
	if (vcard.vcardWorkPhone2) vCard.workPhone.push(vcard.vcardWorkPhone2);
	if (vcard.vcardWorkPhone3) vCard.workPhone.push(vcard.vcardWorkPhone3);
	if (vcard.vcardTitle) vCard.title = vcard.vcardTitle;
	if (vcard.vcardWorkPosition) vCard.role = vcard.vcardWorkPosition;
	if (vcard.vcardGender) vCard.gender = vcard.vcardGender;
	if (vcard.vcardHomeFax) vCard.homeFax.push(vcard.vcardHomeFax);
	if (vcard.vcardWorkFax) vCard.workFax.push(vcard.vcardWorkFax);
	if (vcard.vcardBio) vCard.note = vcard.vcardBio;
	if (vcard.vcardHomeEmail) vCard.email.push(vcard.vcardHomeEmail);
	if (vcard.vcardEmail) vCard.email.push(vcard.vcardEmail);
	if (vcard.vcardEmail2) vCard.email.push(vcard.vcardEmail2);
	if (vcard.vcardEmail3) vCard.email.push(vcard.vcardEmail3);
	if (vcard.vcardWorkEmail) vCard.workEmail.push(vcard.vcardWorkEmail);
	if (vcard.vcardWorkEmail2) vCard.workEmail.push(vcard.vcardWorkEmail2);
	if (vcard.vcardWorkEmail3) vCard.workEmail.push(vcard.vcardWorkEmail3);
	if (vcard.vcardWebsite) vCard.url.push(vcard.vcardWebsite);
	if (vcard.vcardWebsite2) vCard.url.push(vcard.vcardWebsite2);
	if (vcard.vcardWebsite3) vCard.url.push(vcard.vcardWebsite3);
	if (vcard.vcardWorkUrl) vCard.workUrl.push(vcard.vcardWorkUrl);
	if (vcard.vcardWorkUrl2) vCard.workUrl.push(vcard.vcardWorkUrl2);
	if (vcard.vcardWorkUrl3) vCard.workUrl.push(vcard.vcardWorkUrl3);

	// set address information
	if (vcard.vcardAddress) {
		if (vcard.vcardAddress.work) {
			const {address} = vcard.vcardAddress.work;
			if (address) {
				vCard.workAddress.label = 'Work Address';
				vCard.workAddress.street = `${address.route} ${address.street_number}`;
				vCard.workAddress.city = address.locality;
				vCard.workAddress.stateProvince = address.administrative_area_level_1;
				vCard.workAddress.postalCode = address.postal_code;
				vCard.workAddress.countryRegion = address.country;
			}
		}

		if (vcard.vcardAddress.home) {
			const {address} = vcard.vcardAddress.home;
			if (address) {
				vCard.homeAddress.label = 'Home Address';
				vCard.homeAddress.street = `${address.route} ${address.street_number}`;
				vCard.homeAddress.city = address.locality;
				vCard.homeAddress.stateProvince = address.administrative_area_level_1;
				vCard.homeAddress.postalCode = address.postal_code;
				vCard.homeAddress.countryRegion = address.country;
			}
		}
	}
	// set social media URLs
	//vCard.socialUrls = vcard.socialUrls || {};

	vCard.version = '3.0';
	return vCard.getFormattedString()
}

// Named exports
export { formatSize, cleanSignatureHTML, toast, updateJson, arrayBufferToBase64,  imgSquareToRound, generateQRCode, generateVcard };
