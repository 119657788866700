import Api from './Api'

export default {
	exchangeAuthCode (data) {
		return Api().post('/users/auth-code', data)
	},
	microsoftLogin (data) {
		return Api().post('/users/microsoft/sdk', data)
	},
	microsoftOBO (data) {
		return Api().post('/users/microsoft/obo', data)
	}
}
