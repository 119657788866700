
import Vuex from "vuex";
import { defaultConfig, mockupConfig, linksList } from '@/store/defaults';
import AuthService from '@/services/AuthService';
import to from "await-to-js";
import axios from "axios";
import router from '@/router'
import SignatureService from '@/services/SignatureService';
import { imgSquareToRound, toast} from '@/utils/utils';

const baseURL = import.meta.env.VITE_API_ROOT
const imagePlaceholderURL = import.meta.env.VITE_IMAGE_PLACEHOLDER_URL;
const logoPlaceholderURL = import.meta.env.VITE_LOGO_PLACEHOLDER_URL;

export default new Vuex.Store({
	state: {
		isLoggedIn: false,
		sidebarSelected: 'layouts',
		selectedTemplate: "template1",
		isSignatureSaved: true,
		signatures: [],
		user: {},
		signatureName: "",
		signatureID: "",
		config: JSON.parse(JSON.stringify(defaultConfig)),
		outlookDefaultSignatures: { newMail: null,  reply: null, forward: null, internal: null }
	},
	getters: {
	},
	actions: {
		async exchangeAuthCode( { state, commit, rootState, dispatch, getters }, data) {
			const [err, response] = await to(AuthService.exchangeAuthCode(data));
			if (err) return toast.error(err.response?.data?.error || err.message || err.code);
			await dispatch("loginSetup", response.data);
			return true;
		},
		async microsoftLogin( { state, commit, rootState, dispatch, getters }, data) {
			const [err, response] = await to(AuthService.microsoftLogin(data));
			if (err) return toast.error(err.response?.data?.error || err.message || err.code);
			return response.data;
		},
		async microsoftOBO( { state, commit, rootState, dispatch, getters }, data) {
			const [err, response] = await to(AuthService.microsoftOBO(data));
			if (err) return toast.error(err.response?.data?.error || err.message || err.code);
			return response.data;
		},
		async loginSetup({ state, getters, commit, rootState, dispatch }, data) {
			if (data.data.id) commit("setUserID", data.data.id);
			if (data.data.CompanyId) commit("setCompanyID", data.data.CompanyId);
			if (data.refreshToken) commit("setRefreshToken", data.refreshToken);
			if (data.token) commit("setToken", data.token);

			commit("saveUserToLocalStorage", (data.data));
			commit("setUserConfig", data.data);
			commit("setIsLoggedIn", true);
		},
		async refreshTokenAction({ state, commit, rootState, dispatch }, data) {
			const [err, response] = await to(
				axios.get(baseURL + "/v1/users/refresh-token", {
					params: {
						UserId: data.userID,
						token: data.refreshToken
					},
				})
			);
			if (err && err.response && err.response.data.error === "ACCESS_DENIED") {
				dispatch("logout", false);
				return location.reload();
			}
			// Set new access token
			commit("setToken", response.data.token);
			if(response.data.refreshToken) commit("setRefreshToken", response.data.refreshToken);
		},
		async logout({ state, commit, dispatch }, sendRequest = true) {

			localStorage.removeItem('userID');
			localStorage.removeItem('companyID');
			localStorage.removeItem('token');
			localStorage.removeItem('refreshToken');
			localStorage.removeItem('user');
			commit("setIsLoggedIn", false)
			commit("setDefaultSignatureConfig");
		},
		async newSignature({ state, commit, dispatch }, data) {
			if (state.isLoggedIn) {
				await commit('setSignatureConfigFromTapniUser');
			}

			let newSignatureData = {
				name: data.name,
				template: 'template1',
				data: JSON.parse(JSON.stringify(state.config))
			};

			const [err, response] = await to(SignatureService.saveSignature(newSignatureData));
			if (err) return toast.error(err.response?.data?.error || err.message || err.code);
			else {
				commit("setSignatureSaved", true);
				return response.data;
			}
		},
		async saveSignature({ state, commit, dispatch }, data) {
			const [err, response] = await to(SignatureService.saveSignature(data));
			if (err) return toast.error(err.response?.data?.error || err.message || err.code);
			else {
				commit("setSignatureSaved", true);
				return response.data;
			}
		},
		async getSignatureTemplate({ state, commit, dispatch }, data) {
			const [err, response] = await to(SignatureService.getSignatureTemplate(data));
			if (err) return toast.error(err.response?.data?.error || err.message || err.code);

			const template = response.data.templates[0];
			return {
				data: {
					id: template.id,
					name: template.name,
					template: template.config.layout,
					data: template.config
				}
			};
		},
		async getSignature({ state, commit, dispatch }, data) {
			const [err, response] = await to(SignatureService.getSignature(data));
			if (err) return toast.error(err.response?.data?.error || err.message || err.code);
			else return response.data;
		},
		async getSignatures({ state, commit, dispatch }) {
			const [err, response] = await to(SignatureService.getSignatures());
			if (err) return toast.error(err.response?.data?.error || err.message || err.code);
			else {
				if (response.data?.data) commit("setSignatures", response.data.data);
			}
		},
		async generateSignature({ state, commit, dispatch }, data) {
			const [err, response] = await to(SignatureService.generateSignature(data));
			if (err) return toast.error(err.response?.data?.error || err.message || err.code);
			else return response.data;
		},
		async renameSignature({ state, commit, dispatch }, data) {
			const [err, response] = await to(SignatureService.renameSignature(data));
			if (err) return toast.error(err.response?.data?.error || err.message || err.code);
			else {
				if (router.currentRoute.value.path.startsWith('/dashboard')) await dispatch('getSignatures');
				else commit("setSignatureName", data.name);
				return response.data;
			}
		},
		async duplicateSignature({ state, commit, dispatch }, data) {
			const [err, response] = await to(SignatureService.duplicateSignature(data));
			if (err) return toast.error(err.response?.data?.error || err.message || err.code);
			else {
				if (router.currentRoute.value.path.startsWith('/dashboard')) await dispatch('getSignatures');
				// commit("setSignatureID", response.data.signatureID);
				// commit("setSignatureName", response.data.name);
				return response.data;
			}
		},
		async deleteSignature({ state, commit, dispatch }, data) {
			const [err, response] = await to(SignatureService.deleteSignature(data));
			if (err) return toast.error(err.response?.data?.error || err.message || err.code);
			else {
				if (router.currentRoute.value.path.startsWith('/dashboard')) await dispatch('getSignatures');
				else {
					commit("setSignatureID", '');
					commit("setSignatureName", '');
				}
				return response.data;
			}
		},
		async getContactCardVCFData({ state, commit, dispatch }, data) {
			const [err, response] = await to(axios.get(data.url, { responseType: 'arraybuffer'}));
			if (err) return toast.error(err.response?.data?.error || err.message || err.code);
			if(response && response.data) return response.data;
			else return null;
		}
	},
	mutations: {
		setUserID(state, userID) {
			//state.userID = userID;
			localStorage.setItem('userID', userID) ;
		},
		setCompanyID(state, companyID) {
			//state.companyID = companyID;
			localStorage.setItem('companyId', companyID) ;
		},
		setToken(state, token) {
			//state.token = token;
			localStorage.setItem('token', token) ;
		},
		setRefreshToken(state, token) {
			//state.refreshToken = token;
			localStorage.setItem('refreshToken', token) ;
		},
		saveUserToLocalStorage(state, data) {
			const userObject = {
				...data,
				contactCardLink: data.links?.find(e => e.type === 'contactcard')?.url || null
			};
			localStorage.setItem('user', JSON.stringify(userObject));
		},
		setUserConfig(state, data) {
			state.user = {
				...data,
				contactCardLink: data.links?.find(e => e.type === 'contactcard')?.url || null
			};
		},
		setIsLoggedIn(state, data) {
			state.isLoggedIn = data;
		},
		setSidebarSelected(state, name) {
			state.sidebarSelected = name;
		},
		setSelectedTemplate(state, templateID) {
			state.selectedTemplate = templateID;
		},
		setSignatureName(state, name) {
			state.signatureName = name;
		},
		setSignatureID(state, id) {
			state.signatureID = id;
		},
		// set default signature configuration, configuration is stored in defaults.js
		setDefaultSignatureConfig(state) {
			state.signatureID = "";
			state.signatureName = "";
			state.config = JSON.parse(JSON.stringify(defaultConfig));
		},
		// set mockup signature configuration, configuration is stored in defaults.js
		setSignatureConfigFromMockup(state) {
			state.config = JSON.parse(JSON.stringify(mockupConfig));
		},
		// set  signature configuration
		setSignatureConfig(state, data) {

			data = JSON.parse(JSON.stringify(data));

			if (data.person) {
				// prevent image overwrite
				data.person.image = state.config.person.image;
				// prevent aspectRatio overwrite
				data.person.aspectRatio = state.config.person.aspectRatio;
				// if placeholder switch is true
				if (data.person.showImagePlaceholder) data.person.image = imagePlaceholderURL;
				else if(data.person.image === imagePlaceholderURL) data.person.image = '';
				state.config.person = data.person;
			}
			if (data.business) {
				// prevent logo overwrite
				data.business.logo = state.config.business.logo;
				// prevent aspectRatio overwrite
				data.business.aspectRatio = state.config.business.aspectRatio;
				// if placeholder switch is true
				if (data.business.showLogoPlaceholder) data.business.logo = logoPlaceholderURL;
				else if(data.business.logo === logoPlaceholderURL) data.business.logo = '';
				state.config.business = data.business;
			}
			if (data.design) state.config.design = data.design;
			if (data.qrCode) {
				// if(state.config.qrCode.image) data.cta.image = state.config.cta.image;
				state.config.qrCode = data.qrCode;
			}
			if (data.cta) {
				// prevent image and aspect ratio overwrite
				if (state.config.cta.image1)  {
					data.cta.image1 = state.config.cta.image1;
				}
				if (state.config.cta.image2) {
					data.cta.image2 = state.config.cta.image2;
				}
				// prevent aspect ratio overwrite
				data.cta.aspectRatio1 = state.config.cta.aspectRatio1;
				data.cta.aspectRatio2 = state.config.cta.aspectRatio2;
				state.config.cta = data.cta;
			}
			if (data.addon) state.config.addon = data.addon;

			state.isSignatureSaved = false;
		},
		// set signature configuration from Tapni user object from store, user object was prefilled from localStorage
		setSignatureConfigFromTapniUser(state) {

			let data = state.user;

			if (data.name) state.config.person.name = data.name;
			if (data.photo) {
				state.config.person.image = data.photo;
				state.config.person.showImagePlaceholder = false;
				state.config.person.aspectRatio = 1;
			}
			if(data.company?.name) state.config.business.company = data.company?.name;
			if(data.company?.logo) state.config.business.logo = data.company?.logo;

			const emailObject = 	{name: "Icon 1", type: "icon1", value: "", icon: "https://cdn.mailsign.link/icons/email.png"};
			let emailObjectClone;
			if (data?.vcard?.vcardWorkEmail) {
				emailObjectClone = { ...emailObject };
				emailObjectClone.value  = data.vcard.vcardWorkEmail;
				if (state.config.person.emails[0]?.value === "" ) state.config.person.emails[0] = emailObjectClone;
				else state.config.person.emails.push(emailObjectClone);
			}
			if (data?.vcard?.vcardEmail) {
				emailObjectClone = { ...emailObject };
				emailObjectClone.value  = data.vcard.vcardEmail;
				if (state.config.person.emails[0]?.value === "" ) state.config.person.emails[0] = emailObjectClone;
				else state.config.person.emails.push(emailObjectClone);
			}
			else if (data?.vcard?.email) {
				emailObjectClone = { ...emailObject };
				emailObjectClone.value  = data.vcard.email;
				if (state.config.person.emails[0]?.value === "" ) state.config.person.emails[0] = emailObjectClone;
				else state.config.person.emails.push(emailObjectClone);
			}

			if (data?.vcard?.vcardFirstName && data?.vcard?.vcardLastName) state.config.person.name = data.vcard.vcardFirstName + " " + data.vcard.vcardLastName;

			const phoneObject = { name: "Phone", type: "phone", value: "", icon: "https://cdn.mailsign.link/icons/phone.png"};
			let phoneObjectClone;
			if (data?.vcard?.vcardPhone) {
				phoneObjectClone = { ...phoneObject };
				phoneObjectClone.value = data.vcard.vcardPhone;
				if (state.config.person.phoneNumbers[0]?.value === "" ) state.config.person.phoneNumbers[0] = phoneObjectClone;
				else state.config.person.phoneNumbers.push(phoneObjectClone);
			}
			if (data?.vcard?.vcardWorkPhone) {
				phoneObjectClone = { ...phoneObject };
				phoneObjectClone.value = data.vcard.vcardWorkPhone;
				if (state.config.person.phoneNumbers[0]?.value === "" ) state.config.person.phoneNumbers[0] = phoneObjectClone;
				else state.config.person.phoneNumbers.push(phoneObjectClone);
			}
			if (data?.vcard?.vcardWorkPhone2) {
				phoneObjectClone = { ...phoneObject };
				phoneObjectClone.value = data.vcard.phoneObjectClone;
				if (state.config.person.phoneNumbers[0]?.value === "" ) state.config.person.phoneNumbers[0] = phoneObjectClone;
				else state.config.person.phoneNumbers.push(phoneObjectClone);
			}
			if (data?.vcard?.vcardWorkPhone3) {
				phoneObjectClone = { ...phoneObject };
				phoneObjectClone.value = data.vcard.vcardWorkPhone3;
				if (state.config.person.phoneNumbers[0]?.value === "" ) state.config.person.phoneNumbers[0] = phoneObjectClone;
				else state.config.person.phoneNumbers.push(phoneObjectClone);
			}

			if (data?.vcard?.vcardTitle) state.config.person.jobTitle = data.vcard.vcardTitle;

			const websiteObject = { name: "Icon 1", type: "icon1", value: "", icon: "https://cdn.mailsign.link/icons/link.png" };
			let webSiteObjectClone;
			if (data?.vcard?.vcardWorkUrl) {
				webSiteObjectClone = { ...websiteObject };
				webSiteObjectClone.value = data.vcard.vcardWorkUrl;
				if (state.config.person.websites[0]?.value === "" ) state.config.person.websites[0] = webSiteObjectClone;
				else state.config.person.websites.push(webSiteObjectClone);
			}
			if (data?.vcard?.vcardWebsite) {
				webSiteObjectClone = { ...websiteObject };
				webSiteObjectClone.value = data.vcard.vcardWebsite;
				if (state.config.person.websites[0]?.value === "" ) state.config.person.websites[0] = webSiteObjectClone;
				else state.config.person.websites.push(webSiteObjectClone);
			}

			const addressObject = { name: "Icon 1",  type: "icon1",  value: "", value2: "",  icon: "https://cdn.mailsign.link/icons/address.png" };
			let addressObjectClone;
			if (data?.vcard?.vcardAddress?.work?.place?.formatted_address) {
				addressObjectClone = { ...addressObject };
				addressObjectClone.value = data?.vcard?.vcardAddress?.work?.place?.formatted_address;
				if (data?.vcard?.vcardAddress?.work?.place?.url) addressObjectClone.value2 = data?.vcard?.vcardAddress?.work?.place?.url;
				if (state.config.person.addresses[0]?.value === "" ) state.config.person.addresses[0] = addressObjectClone;
				else state.config.person.addresses.push(addressObjectClone);
			}
			else if (data?.vcard?.vcardAddress?.home?.place?.formatted_address) {
				addressObjectClone = { ...addressObject };
				addressObjectClone.value = data?.vcard?.vcardAddress?.home?.place?.formatted_address;
				if (data?.vcard?.vcardAddress?.home?.place?.url) addressObjectClone.value2 = data?.vcard?.vcardAddress?.home?.place?.url;
				if (state.config.person.addresses[0]?.value === "" ) state.config.person.addresses[0] = addressObjectClone;
				else state.config.person.addresses.push(addressObjectClone);
			}

			if (data.vcard?.socialUrls && (Object.keys(data.vcard.socialUrls).length > 0)) {
				Object.values(data.vcard.socialUrls).forEach(link => {
					const linkFromList = linksList.find(e => e.type === link.type);
					if (linkFromList) {
						linkFromList.value = link.url;
						if(state.config.person.links[0]?.value === "" ) state.config.person.links[0] = linkFromList;
						else state.config.person.links.push(linkFromList);
					}
				})
			}

			if(data.username) {
				const tapniLink = linksList.find(e => e.type === 'tapni');
				tapniLink.value = "https://t.link/" + data.username;
				if (state.config.person.links[0]?.value === "" ) state.config.person.websites[0] = tapniLink;
				else state.config.person.links.push(tapniLink);
			}

			state.isSignatureSaved = false;
		},
		setPicture(state, data) {
			if (data.type === 'avatar') {
				state.config.person.image = data.picture;
				state.config.person.showImagePlaceholder = false;
				state.config.person.aspectRatio = data.aspectRatio;
			}
			if (data.type === 'logo'){
				state.config.business.logo = data.picture;
				state.config.business.showLogoPlaceholder = false;
				state.config.business.aspectRatio = data.aspectRatio;
			}
			if (data.type === 'ctaImage1') {
				state.config.cta.image1 = data.picture;
				if (data.aspectRatio) state.config.cta.aspectRatio1 = data.aspectRatio;
			}
			if (data.type === 'ctaImage2') {
				state.config.cta.image2 = data.picture;
				if (data.aspectRatio) state.config.cta.aspectRatio2 = data.aspectRatio;
			}
		},
		setPicturesAfterUpload(state, data) {
			if (data) {
				state.config.person.image = data.person?.image;
				state.config.business.logo = data.business?.logo;
				state.config.cta.image1 = data.cta?.image1;
				state.config.cta.image2 = data.cta?.image2;
				if (data.cta?.aspectRatio1) state.config.cta.aspectRatio1 = data.cta?.aspectRatio1;
				if (data.cta?.aspectRatio2) state.config.cta.aspectRatio2 = data.cta?.aspectRatio2;
			}
		},
		setSignatureSaved(state, data) {
			state.isSignatureSaved = data;
		},
		setSignatures(state, data) {
			state.signatures = data;
		},
		setOutlookDefaultSignatures(state, data) {
			state.outlookDefaultSignatures = data;
		}
	}
});
