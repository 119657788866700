<template>
  <div v-if="toggle" class="modal-overlay" @click="closeModal">
    <div class="modal-content modal-size" @click.stop>
      <div class="row">
        <div class="my-3 col-12 text-center">
          <h5>Duplicate Signature</h5>
        </div>
        <div class="my-3 col-12">
          <div class="row justify-content-center">
            <div class="col-10">
              <input type="text" v-model="name" class="form-control br15" placeholder="Enter the name of the duplicated signature">
            </div>
          </div>
          <div class="row justify-content-center mt-4">
            <a class="btn btn-secondary" @click="closeModal">Cancel</a>
            <a class="btn btn-primary" @click="duplicateSignatureConfirmed">Duplicate</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emitter from '../../eventBus'
import { mapActions } from 'vuex';
import { toast } from '@/utils/utils';

export default {
  name: 'DuplicateSignature',
  data() {
    return {
      toggle: false,
      name: "",
      signatureID: ""
    }
  },
  mounted() {
    const self = this;
    emitter.on('openDuplicateSignatureModal', function (data) {
      if (data.signatureID){
        self.signatureID = data.signatureID;
        self.showModal();
      } else console.log("Signature ID missing...")
    });
  },
  methods: {
    ...mapActions(['duplicateSignature']),
    showModal() {
      this.toggle = true;
      this.name = "";
    },
    closeModal() {
      this.toggle = false;
    },
    async duplicateSignatureConfirmed() {
      emitter.emit('openLoadingModal', {text: 'Duplicating...'});
      const result = await this.duplicateSignature({name: this.name, signatureID: this.signatureID});
      emitter.emit('closeLoadingModal');
      if (result.signatureID) {
        this.toggle = false;
        if(!this.$route.path.startsWith('/dashboard')) return this.$router.push('/signature/' + result.signatureID);
        toast('Signature was duplicated successfully');
      }
    }
  }
}
</script>

<style scoped>

</style>
