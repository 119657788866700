<template>
  <div v-if="toggle" class="modal-overlay">
    <div class="modal-content modal-size" style="width: 500px !important;">
      <h2 class="loadingText">{{ text }}</h2>
    </div>
  </div>
</template>

<script>
import emitter from '../../eventBus'
export default {
  name: 'LoadingModal',
  data() {
    return {
      toggle: false,
      text: 'Loading'
    }
  },
  mounted() {
    const self = this;
    emitter.on('openLoadingModal', function (data) {
      if (data && data.text) self.text = data.text
      else self.text = 'Loading'
      self.showModal();
    });
    emitter.on('closeLoadingModal', function () {
      self.closeModal();
    });
  },
  methods: {
    showModal() {
      this.toggle = true;
    },
    closeModal() {
      this.toggle = false;
    },
  }
}
</script>

<style scoped>
  .modal-overlay {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .modal-content {
    background-color: transparent;
  }

  .loadingText {
    color: white;
    text-align: center;
  }

  .loadingText:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {
    0%, 20% {
      color: rgba(0,0,0,0);
      text-shadow:
          .25em 0 0 rgba(0,0,0,0),
          .5em 0 0 rgba(0,0,0,0);}
    40% {
      color: white;
      text-shadow:
          .25em 0 0 rgba(0,0,0,0),
          .5em 0 0 rgba(0,0,0,0);}
    60% {
      text-shadow:
          .25em 0 0 white,
          .5em 0 0 rgba(0,0,0,0);}
    80%, 100% {
      text-shadow:
          .25em 0 0 white,
          .5em 0 0 white;
    }
  }
</style>
