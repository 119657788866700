import 'bootstrap/dist/css/bootstrap.css'
import "vue-toastification/dist/index.css";
import 'vue-select/dist/vue-select.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'proxy-polyfill'; // Add this line to polyfill Proxy
import router from './router'
import store from './store/index'
import { formatSize } from "./utils/utils";
import Toast, { POSITION } from "vue-toastification";
// import { Tooltip } from 'bootstrap';


import { createApp } from "vue";
import App from "./App.vue";

const app = createApp(App);

app.config.globalProperties.$formatSize = formatSize;

app.use(Toast, {
	position: POSITION.BOTTOM_RIGHT,
	icon: false,
	timeout: 5000,
	toastClassName: "toast-class",
	containerClassName: "toast-class"
});

/*
app.directive('b-tooltip', {
	mounted(el, binding) {
		let tooltip = new Tooltip(el, {
			title: binding.value,
			placement: binding.arg || 'top', // default placement
			trigger: 'hover focus',
			container: 'body'
		});

		el._tooltip = tooltip; // Store instance for future reference
	},
	unmounted(el) {
		if (el._tooltip) {
			el._tooltip.dispose();
			delete el._tooltip;
		}
	}
})

 */

app.use(router);
app.use(store);
app.mount("#app");

import "bootstrap/dist/js/bootstrap.js"

