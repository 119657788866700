<template>
  <div v-if="toggle" class="modal-overlay" @click="closeModal">
    <div class="modal-content modal-size" @click.stop>
      <div class="row">
        <div class="pb-2 col-12">
          <div class="col-md-12" style="text-align: center;">
            <h5 class="title">Your signature is ready!</h5>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12 text-end d-flex justify-content-center">
          <button type="button" class="btn btn-primary" @click="copySignature('signature')">Copy Signature</button>
          <button type="button" class="btn btn-primary" @click="copySignature('source')">Copy Source Code</button>
        </div>
      </div>
      <div class="mt-3">
        <p class="description">For Gmail, Outlook or WYSIWYG editors, use the 'Copy Signature' option</p>
      </div>
      <div class="row mt-1" v-if="!previewSource">
        <p class="previewSource" @click="previewSource = true">Preview source code</p>
      </div>
      <div class="row mt-1" v-if="previewSource">
        <textarea class="form-control col-12 generatedSignature" rows="20" readonly>{{html}}</textarea>
      </div>
    </div>
  </div>
</template>

<script>
import emitter from '../../eventBus'
import { toast } from '@/utils/utils';

export default {
  name: 'GeneratedSignature',
  data() {
    return {
      toggle: false,
      html: '',
      previewSource: false
    }
  },
  mounted() {
    const self = this;
    emitter.on('openGeneratedSignatureModal', function (data) {
      self.html = data.html;
      self.previewSource = false;
      self.showModal();
    });
  },
  methods: {
    showModal() {
      this.toggle = true;
    },
    closeModal() {
     this.toggle = false;
    },
    async copySignature(type) {
      if (type === 'signature') {
        // set background to white in case that the signature dark mode is on
        const previewColor = document.querySelector('.card-preview').style.backgroundColor;

        // set background to white in case that the signature dark mode is on
        document.querySelector('.card-preview').style.backgroundColor = '#FFFFFF'

        let element = document.getElementById('signature-html');

        // add helper div with text-decoration-none style
        element.insertAdjacentHTML('afterbegin', '<div><style>.text-decoration-none a{text-decoration:none!important;}</style></div>');

        /*
        const selection = window.getSelection()
        const range = document.createRange();
        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
        selection.removeAllRanges();
         */
        /*
       let t = new clipboard.DT;
       t.setData("text/plain", element.innerHTML);
       t.setData("text/html", element.innerHTML);
       await clipboard.write(t);

         */

        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([element.innerHTML], { type: 'text/html' })
          })
        ]);

        // remove helper div with text-decoration-none style and <br>
        element.firstChild.remove();

        // restore the background color in case that signature dark mode is on
        document.querySelector('.card-preview').style.backgroundColor = previewColor;
      }
      else if (type === 'source') navigator.clipboard.writeText(this.html);
      toast("The email signature code is copied to the clipboard");
    },
  }
}
</script>

<style scoped>

  .generatedSignature{
    color: #5e5e5e;
    font-size: 12px;
  }

  .modal-content {
    width: 700px
  }

  .title {
    padding-top: 5px;
    color: rgb(29, 29, 29);
  }

  .description {
    font-style: italic;
    font-size: 12px;
    color: grey;
    text-align: center;
  }

  .previewSource {
    text-align: center;
    color: #1992ff;
  }

</style>
