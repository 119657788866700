const imagePlaceholderURL = import.meta.env.VITE_IMAGE_PLACEHOLDER_URL;
const logoPlaceholderURL = import.meta.env.VITE_LOGO_PLACEHOLDER_URL;


const defaultConfig = {
	person: {
			image: imagePlaceholderURL,
			showImagePlaceholder: true,
			imageWidth: 150,
			aspectRatio: 1,
			name: "",
			jobTitle: "",
			addresses: [
				{
					name: "Icon 1",
					type: "icon1",
					value: "",
					value2: "",
					icon: "https://cdn.mailsign.link/icons/address.png",
				},
			],
			emails: [
				{
					name: "Icon 1",
					type: "icon1",
					value: "",
					icon: "https://cdn.mailsign.link/icons/email.png",
				},
			],
			websites: [
				{
					name: "Icon 1",
					type: "icon1",
					value: "",
					icon: "https://cdn.mailsign.link/icons/link.png",
				}
			],
			phoneNumbers: [
				{
					name: "Phone",
					type: "phone",
					value: "",
					icon: "https://cdn.mailsign.link/icons/phone.png",
				}
			],
			links: [
				{
					name: "LinkedIn",
					type: "linkedin",
					value: "",
					icon: "https://cdn.mailsign.link/icons/linkedin.png"
				}
			]
	},
	business: {
			logo: logoPlaceholderURL,
			showLogoPlaceholder: true,
			logoWidth: 150,
			aspectRatio: 3.175,
			company: "",
	},
	design: {
		nameColor: '',
		jobTitleColor: '#888888',
		companyColor: '#888888',
		nameSize: 13,
		jobTitleSize: 13,
		companySize: 13,
		showIcons: true,
		iconSize: 18,
		socialMediaIconSize: 30,
		showDividingLines: true,
		dividingLineSize: 2,
		dividingLineColor: '#1992ff',
		spacesSize: 1,
		linkColor: '#1992ff',
		createdWithMailsign: true
	},
	qrCode: {
		showQR: false,
		type: 'tapniQR',
		style: 'extra-rounded',
		color: '#000000',
		size: 120,
		value: 'https://t.link/',
		image: ''
	},
	cta: {
			image1: "",
			image1Width: 100,
			aspectRatio1: 1,
			link1: "",
			image2: "",
			image2Width: 100,
			aspectRatio2: 1,
			link2: ""
	},
	addon: {
		greenMessageText: 'Please consider the environment before printing this email.',
		showGreenMessage: false,
		disclaimerText: "The content of this email is confidential and intended for the recipient specified in message only. It is strictly forbidden to share any part of this message with any third party, without a written consent of the sender. If you received this message by mistake, please reply to this message and follow with its deletion, so that we can ensure such a mistake does not occur in the future.",
		showDisclaimer: false
	}
}


const mockupConfig = {
	person: {
		image: imagePlaceholderURL,
		showImagePlaceholder: true,
		imageWidth: 200,
		aspectRatio: 1,
		name: "John Doe",
		jobTitle: "Marketing Manager",
		addresses: [
			{
				name: "Icon 1",
				type: "icon1",
				value: "987 Mozartplatz Vienna, Austria 1010",
				value2: "",
				icon: "https://cdn.mailsign.link/icons/address.png",
			},
		],
		emails: [
			{
				name: "Icon 1",
				type: "icon1",
				value: "john@acme.co",
				icon: "https://cdn.mailsign.link/icons/email.png",
			},
		],
		websites: [
			{
				name: "Icon 1",
				type: "icon1",
				value: "https://example.org",
				icon: "https://cdn.mailsign.link/icons/link.png",
			}
		],
		phoneNumbers: [
			{
				name: "Phone",
				type: "phone",
				value: "",
				icon: "https://cdn.mailsign.link/icons/phone.png",
			}
		],
		links: [
			{
				name: "LinkedIn",
				type: "linkedin",
				value: "",
				icon: "https://cdn.mailsign.link/icons/linkedin.png"
			}
		]
	},
	business: {
		logo: logoPlaceholderURL,
		showLogoPlaceholder: true,
		logoWidth: 150,
		aspectRatio: 3.175,
		company: "Acme Corporation",
	},
	design: {
		nameColor: '',
		jobTitleColor: '#888888',
		companyColor: '#888888',
		nameSize: 13,
		jobTitleSize: 13,
		companySize: 13,
		showIcons: true,
		iconSize: 18,
		socialMediaIconSize: 30,
		showDividingLines: true,
		dividingLineSize: 2,
		dividingLineColor: '#1992ff',
		linkColor: '#1992ff',
		createdWithMailsign: true
	},
	qrCode: {
		showQR: false,
		type: 'tapniQR',
		style: 'extra-rounded',
		color: '#000000',
		size: 120,
		value: 'https://t.link/',
		image: ''
	},
	cta: {
		image1: "",
		image1Width: 100,
		aspectRatio1: 1,
		link1: "",
		image2: "",
		image2Width: 100,
		aspectRatio2: 1,
		link2: ""
	},
	addon: {
		greenMessageText: 'Please consider the environment before printing this email.',
		showGreenMessage: false,
		disclaimerText: "The content of this email is confidential and intended for the recipient specified in message only. It is strictly forbidden to share any part of this message with any third party, without a written consent of the sender. If you received this message by mistake, please reply to this message and follow with its deletion, so that we can ensure such a mistake does not occur in the future.",
		showDisclaimer: false
	}
}

const linksList = [
	{
		name: "LinkedIn",
		type: "linkedin",
		icon: "https://cdn.mailsign.link/icons/linkedin.png",
		value: ""
	},
	{
		name: "Twitter",
		type: "twitter",
		icon: "https://cdn.mailsign.link/icons/twitter.png",
		value: ""
	},
	{
		name: "Facebook",
		type: "facebook",
		icon: "https://cdn.mailsign.link/icons/facebook.png",
		value: ""
	},
	{
		name: "Instagram",
		type: "instagram",
		icon: "https://cdn.mailsign.link/icons/instagram.png",
		value: ""
	},
	{
		name: "Youtube",
		type: "youtube",
		icon: "https://cdn.mailsign.link/icons/youtube.png",
		value: ""
	},
	{
		name: "Github",
		type: "github",
		icon: "https://cdn.mailsign.link/icons/github.png",
		value: ""
	},
	{
		name: "Google",
		type: "google",
		icon: "https://cdn.mailsign.link/icons/google.png",
		value: ""
	},
	{
		name: "Tripadvisor",
		type: "tripadvisor",
		icon: "https://cdn.mailsign.link/icons/tripadvisor.png",
		value: ""
	},
	{
		name: "Behance",
		type: "behance",
		icon: "https://cdn.mailsign.link/icons/behance.png",
		value: ""
	},
	{
		name: "Tapni",
		type: "tapni",
		icon: "https://cdn.mailsign.link/icons/tapni.png",
		value: ""
	}
]

const phoneNumbersList =  [
	{
		name: "Phone",
		type: "phone",
		value: "",
		icon: "https://cdn.mailsign.link/icons/phone.png",
	},
	{
		name: "Mobile",
		type: "mobile",
		value: "",
		icon: "https://cdn.mailsign.link/icons/mobile.png",
	},
	{
		name: "Landline",
		type: "landline",
		value: "",
		icon: "https://cdn.mailsign.link/icons/landline.png",
	},
	{
		name: "Fax",
		type: "fax",
		value: "",
		icon: "https://cdn.mailsign.link/icons/fax.png",
	}
]

const addressesList =  [
	{
		name: "Icon 1",
		type: "icon1",
		value: "",
		icon: "https://cdn.mailsign.link/icons/address.png",
	},
	{
		name: "Icon 2",
		type: "icon2",
		value: "",
		icon: "https://cdn.mailsign.link/icons/location2.png",
	}
]

const websitesList =  [
	{
		name: "Tapni Icon",
		type: "icon1",
		value: "",
		icon: "https://cdn.mailsign.link/icons/tapni.png",
	},
	{
		name: "Link Icon",
		type: "icon2",
		value: "",
		icon: "https://cdn.mailsign.link/icons/link.png",
	},
	{
		name: "Calendar Icon",
		type: "icon3",
		value: "",
		icon: "https://cdn.mailsign.link/icons/calendar1.png",
	}
]

const emailsList =  [
	{
		name: "Icon 1",
		type: "icon1",
		value: "",
		value2: "",
		icon: "https://cdn.mailsign.link/icons/email.png",
	},
	{
		name: "Icon 2",
		type: "icon2",
		value: "",
		value2: "",
		icon: "https://cdn.mailsign.link/icons/email2.png",
	},
	{
		name: "Icon 3",
		type: "icon3",
		value: "",
		value2: "",
		icon: "https://cdn.mailsign.link/icons/email3.png",
	},
]

export { defaultConfig, mockupConfig, linksList, phoneNumbersList, addressesList, websitesList, emailsList };
