import {createRouter, createWebHistory} from 'vue-router';

const LandingPage = () => import('@/views/LandingPage.vue');
const Dashboard = () => import('@/views/Dashboard.vue');
const SignatureEditor = () => import('@/views/SignatureEditor.vue');
const OutlookAddon  = () => import('@/views/OutlookAddon.vue');

const routes =  [
		{
			path: '/',
			name: 'LandingPage',
			component: LandingPage
		},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: Dashboard
	},
	{
		path: '/signature',
		name: 'SignatureEditor',
		component: SignatureEditor
	},
	{
		path: '/signature/:signatureID',
		component: SignatureEditor
	},
	{
		path: '/outlook-addon',
		component: OutlookAddon
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'LandingPage',
		component: LandingPage
	},
	/*
		{
				path: '/signature',
				component: SignatureEditor,
				children: [
					{
						path: '',
						redirect: { name: "L" },
					},
					{
						path: 'layouts',
						name: 'Layouts',
						component: Layouts
					},
					{
						path: 'person',
						name: 'Person',
						component: Person
					},
					{
						path: 'business',
						name: 'Business',
						component: Business
					},
					{
						path: 'design',
						name: 'design',
						component: Design
					},
					{
						path: 'cta',
						name: 'Cta',
						component: Cta
					},
					{
						path: 'addon',
						name: 'Addon',
						component: Addon
					},
					{
						path: 'preview',
						name: 'Preview',
						component: PreviewMobile
					}
		]
	},
	 */
]

const router = createRouter({
	history: createWebHistory(),
	routes
});

router.beforeEach((to, from, next) => {
	let isAuth = localStorage.getItem('token')
		if (to.path === '/dashboard'){
			if (isAuth) next();
			else next('/')
		} else next();
})

export default router;
