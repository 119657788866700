<template>
    <div class="container-fluid">
      <router-view :key="$route.fullPath"></router-view>
      <Login/>
      <OutlookAutoLogin/>
      <RenameSignature v-if="isLoggedIn"/>
      <DuplicateSignature v-if="isLoggedIn"/>
      <DeleteSignature v-if="isLoggedIn"/>
      <NotSaved v-if="isLoggedIn" />
      <LoadingModal/>
      <GeneratedSignatureModal></GeneratedSignatureModal>
    </div>
</template>

<script>

import GeneratedSignatureModal from '@/components/modals/GeneratedSignature.vue';

const iframeAuthURL = import.meta.env.VITE_IFRAME_AUTH_URL;
import emitter from '@/eventBus';
import OutlookAutoLogin from "./components/modals/OutlookAutoLogin.vue";
import Login from "./components/modals/Login.vue";
import {mapActions, mapState} from 'vuex';
import { toast } from '@/utils/utils';
import DuplicateSignature from '@/components/modals/DuplicateSignature.vue';
import DeleteSignature from '@/components/modals/DeleteSignature.vue';
import RenameSignature from '@/components/modals/RenameSignature.vue';
import NotSaved from '@/components/modals/NotSaved.vue';
import LoadingModal from '@/components/modals/Loading.vue';

 export default {
   name: "App",
   components: {
     GeneratedSignatureModal,
     RenameSignature,
     DuplicateSignature,
     Login,
     OutlookAutoLogin,
     DeleteSignature,
     NotSaved,
     LoadingModal
   },
   computed: {
     ...mapState(["selectedTemplate", "config", 'isLoggedIn'])
   },
   methods: {
     ...mapActions(["exchangeAuthCode", "saveSignature"]),
     async iframeMessage(event) {
       if (event.origin === iframeAuthURL) {
         const { code, state } = event.data;
         if (code) {
           await this.exchangeAuthCode({code});
           emitter.emit('closeLoginModal');
           if (this.$route.path === '/' ||  this.$route.path.includes('login')) this.$router.push('/dashboard');
           else {
             toast('Login successful!');
             if (state === 'save_signature') {
               emitter.emit('openLoadingModal', {text: 'Saving...'});
               let response = await this.saveSignature({template: this.selectedTemplate, data: this.config});
               emitter.emit('closeLoadingModal');
               if (response && response.signatureID) {
                 this.$store.commit('setSignatureID', response.signatureID);
                 this.$router.push('/signature/' + response.signatureID);
                 emitter.emit('openRenameSignatureModal', {signatureID: response.signatureID, name: ""});
               }
             } else if (state === 'outlook_addon') this.$router.push('/outlook-addon');
           }
         }
       }
     },
   },
   mounted() {
     window.addEventListener("message", this.iframeMessage, false);
     if (localStorage.getItem('token')) this.$store.commit("setIsLoggedIn", true);
     let user = localStorage.getItem('user');
     if (user) {
       user = JSON.parse(user);
       this.$store.commit('setUserConfig', user);
     }

     if (window.location.pathname.startsWith('/login')) {
       if (user) this.$router.push('/dashboard');
       else emitter.emit('openLoginModal');
     }
   },
   beforeDestroy() {
     window.removeEventListener("message", this.iframeMessage, false);
   }
 }
</script>

<style>

  html {
    height: -webkit-fill-available;
  }

  body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    background-color: #eeeeee;
  }

  label {
    color: #525252;
  }

  .pointer {
    cursor: pointer;
  }

  .card {
    border-radius: 25px;
  }

  .card-header {
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
  }

  .card-footer {
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
  }

  .form-label {
    font-size: 9px;
    font-weight: 600;
    color: gray;
    margin-left: 3px;
  }

  .btn {
    border-radius: 15px !important;
    padding-left: 15px;
    padding-right: 15px;
  }

  .btn:active {
    transform: scale(0.95);
  }

  .btn-primary {
    border: 0px !important;
    background: #1992ff !important;
    color: white !important;
  }

  .btn-secondary {
    border: 0px !important;
  }

  .vc-color-wrap {
    margin-right: 0px !important;
  }

  .Vue-Toastification__toast--default.toast-class {
    background: #1992FF;
    color: white;
  }

  .form-control::placeholder {
    color: #d7d6d6;
  }

  .form-range::-webkit-slider-thumb {
    background-color: #1992FF;
  }

  .vc-color-wrap {
    border-radius: 10px !important;
  }

  .dropdown-menu {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border: 0px;
    border-radius: 15px;
  }

  .cardPadding {
    padding: 15px;
  }

  .br15 {
    border-radius: 15px !important;
  }


  .form-range::-webkit-slider-runnable-track {
    background-color: #d9d9d9 !important;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    width: 500px;
  }

  .modal-content .btn {
      margin-left: 10px;
      margin-right: 10px;
      width: auto;
  }

  .popper {
    background: #FFFFFF !important;
    margin: 0px !important;
    padding: 20px !important;
    border-radius: 15px !important;
    color: #fff;
    font-size: 8px;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
    z-index: 2;
  }

  .popper #arrow {
    top: -10px !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
    z-index: 1;

  }

  .popper #arrow::before {
    background: #FFFFFF !important;
    width: calc(20px - var(--popper-theme-border-width, 0px));
    height: calc(20px - var(--popper-theme-border-width, 0px));
  }

  .tooltipIcon {
    width: 15px;
    height: 15px;
    margin-left: 3px;
    margin-top: 1px;
    fill: gray;
  }

  @media (min-width: 768px) {
    .modal-size {
      max-width: 700px !important; /* Slightly larger max-width for larger screens */
    }
  }

  @media (max-width: 480px) {
    .modal-size {
      width: 95% !important; /* Take up more width on small screens */
      max-width: none; /* Remove max-width restriction */
    }
  }


  .lightDarkSwitch span[aria-checked="true"] .vue-toggles__dot .vue-toggles__text {
    right: 27px !important;
  }

  .lightDarkSwitch span[aria-checked="false"] .vue-toggles__dot .vue-toggles__text {
    left: 27px !important;
  }

</style>
