<template>
  <div v-if="toggle" class="modal-overlay" @click="closeModal">
    <div class="modal-content modal-size" @click.stop>
      <div class="row">
        <div class="my-3 col-12 text-center">
          <h6>You have unsaved changes. Are you sure you want to leave without saving?</h6>
        </div>
      </div>
      <div class="row justify-content-center mt-4">
        <a class="btn btn-secondary" @click="closeModal">Cancel</a>
        <a class="btn btn-primary" @click="leave">Leave</a>
      </div>
     </div>
  </div>
</template>

<script>
import emitter from '../../eventBus'

export default {
  name: 'NotSaved',
  data() {
    return {
      toggle: false,
      next: ""
    }
  },
  mounted() {
    const self = this;
    emitter.on('openNotSavedSignatureModal', function (data) {
      self.next = data.next;
      self.showModal();
    });
  },
  methods: {
    showModal() {
      this.toggle = true;
    },
    closeModal() {
      this.toggle = false;
    },
    async leave() {
      this.toggle = false;
      this.next();
    }
  }
}
</script>

<style scoped>

</style>
