import axios from 'axios'
import store from '@/store/index'
import { jwtDecode } from 'jwt-decode'

const baseURL = import.meta.env.VITE_API_ROOT

export default () => {
	let apiInstance = axios.create({
		baseURL: baseURL + '/v1/',
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'X-Client-Name': 'mailsign',
			'X-Client-Version': '0.1'
		}
	})

	// Add a request interceptor
	apiInstance.interceptors.request.use(async function (config) {
		let token = localStorage.getItem('token');
		let refreshToken = localStorage.getItem('refreshToken');
		// Check refresh token expiration before request is sent
		if (token && refreshToken) {
			const decoded = jwtDecode(token)
			// Check if access token expired
			if (decoded.exp - 30 < Math.floor(Date.now() / 1000)) {

				let userID = decoded.id;
				return store.dispatch('refreshTokenAction', {userID, refreshToken})
					.then(() => {
						config.headers = {
							...config.headers,
							Authorization: `Bearer ${localStorage.getItem('token')}`
						}
						return config;
					})

			}
		}
		return config;
	});

	return apiInstance;
}
